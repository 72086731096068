import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import '../../assets/css/footer.css';
import { Element } from 'react-scroll';

import rede_twitter from '../../assets/images/primera_2025/footer/ic_x_1reunion_2025.png';
import rede_insta from '../../assets/images/primera_2025/footer/ic_ig_1reunion_2025.png';
import rede_ln from '../../assets/images/primera_2025/footer/ic_Linkedin_1reunion_.png';
import logo from '../../assets/images/general/logoampip_.png';

const Footer = () => {
  let location = useLocation();

  return (
    <>
      <Row justify={''} align="middle" style={{ backgroundColor: '#ffffff', padding: '1% 0%' }}>

        <Col xs={{ span: 0, offset: 0 }} md={{ span: 4, offset: 2 }} style={{ textAlign: 'left',backgroundColor:'' }}>
          <a href="https://ampip.org.mx/es/" target="_blank" className="link_sitio monserrat">
            <img src={logo} style={{ width: '150px' }} />
          </a>
        </Col>
        <Col xs={{ span: 7, offset: 0 }} md={{ span: 0, offset: 0 }} style={{textAlign: '' }}>
          <a href="https://ampip.org.mx/es/" target="_blank" className="link_sitio monserrat">
            <img src={logo} style={{ width: '100px' }} />
          </a>
        </Col>

        <Col xs={{ span: 10, offset: 4 }} md={{ span: 6, offset: 10 }}  style={{backgroundColor:'' }}>
          <Row justify={'end'} align={'middle'}>
            <Col xs={{ offset: 1, span: 4 }} md={{ offset: 0, span: 4 }} style={{ padding: '5px 10px 0px 10px', backgroundColor:'' }}>
              <a href="https://twitter.com/ampip" target="_blank"><img className="redes" src={rede_twitter} /></a>
            </Col>
            <Col xs={{ offset: 1, span: 4 }} md={{ offset: 0, span: 4 }} style={{ padding: '5px 10px 0px 10px', backgroundColor:'' }}>
              <a href="https://www.linkedin.com/company/asociaci-n-mexicana-de-parques-industriales" target="_blank"><img className="redes" src={rede_ln} /></a>
            </Col>

            <Col xs={{ offset: 1, span: 4 }} md={{ offset: 0, span: 4 }} style={{ padding: '5px 10px 0px 10px', backgroundColor:'' }}>
              <a href="https://www.instagram.com/ampip.oficial/?hl=es-la" target="_blank"><img className="redes" src={rede_insta} /></a>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row align={'bottom'} justify={''}>
            <Col xs={{ span: 10, offset: 1 }} md={{ span: 9, offset: 2 }} style={{ textAlign: 'left',backgroundColor:'' }}>
              <a href="https://ampip.org.mx/es/" target="_blank" className="link_sitio monserrat">www.ampip.org.mx</a>
            </Col>
            <Col  xs={{ span: 13, offset: 0 }} md={{ span: 10, offset: 1 }} style={{backgroundColor:'' }}>
              <p className='leyenda'>Dudas e información con Cecilia Toribio / <strong><a style={{ color: '#263259' }} href="mailto:ctoribio@ampip.org.mx">ctoribio@ampip.org.mx</a></strong></p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Footer;