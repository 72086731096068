import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import RegistroCompleto from "./RegistroCompleto";
import "../../assets/css/login.css";
import { Auth } from "../../utils/AuthContext";
import axios from 'axios';

import banner from "../../assets/images/primera_2025/banner_principal_.jpg";
import fecha from "../../assets/images/temp/50x50.png";
import lugar from "../../assets/images/temp/50x50.png";

import logo_banner_ergistro from "../../assets/images/primera_2025/logo_1reunion_2025.png";


import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  notification,
  Modal,
  Spin,
  InputNumber,
  DatePicker,
  message,
} from "antd";
import Firebase from "../../utils/firebaseConfig";
import {
  mail_registro_confirmacion,
  existe_empresa,
  obtenerCuposActividades
} from "../../utils/ConexionServidor";

import { withRouter } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";

import img_ayuda from "../../assets/images/segunda_2024/PNG/btn_probelmas.png";
import img_ayuda_en from "../../assets/images/segunda_2024/PNG/btn_probelmas.png";

import LoginExiste from "../registro/LoginExiste";

const openNotificationWithIcon = (type, titulo, texto) => {
  notification[type]({
    message: titulo,
    description: texto,
    duration: 10,
  });
};

const nom_evento = "primera-reunion-2025";

const { Option } = Select;

let correo_registrado = "",
  password_registrado = "",
  perfil_registrado = "";

const app = new Firebase();

const success = () => {
  openNotificationWithIcon("success", "Bienvenido", "");
};

const Registro = ({ setsignup, history, tipo_registro, idioma }) => {
  const [otro_medio, setOtroMedio] = useState(false);
  const [confirmacion, setConfirmacion] = useState(false);
  const [cargando_regsitro, setCargandoRegistro] = useState(false);
  const { usuario, datos } = useContext(Auth);
  const [mostrar_login, setMostrarLogin] = useState(false);
  const [reutilizar_datos, setReutilizarDatos] = useState(false);
  const [tipo_perfl, setTipoPerfil] = useState("");
  const [tipo_hotel, setTipoHotel] = useState("");
  const [tipo_membresia, setTipoMembresia] = useState("");
  const [nombre_completo, setNombreCompleto] = useState("");
  const [empresa_repetida, setEmpresaRepetida] = useState(false);
  const [perfil_elegido, setPerfilElegido] = useState(false);
  const [personasInscritas, setPersonasInscritas] = useState(1);

  const [ver_actividades, setVerActividades] = useState(false);
  const [form] = Form.useForm();

  const [cupos, setCupos] = useState([]);
  const [cupos2, setCupos2] = useState([]);
  const [options, setOptions] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
    option5: false,
    option6: false,
    option7: false,
    option8: false,
    option9: false,
    option10: false,
    option11: false,
    option12: false,
    option13: false,
  });


  /*useEffect(() => {
    const fetchCupos = async () => {
      const data = await obtenerCuposActividades();
      if (data) {
        setCupos(data.data); // Actualiza el estado con los cupos de actividades
      }
    };

    fetchCupos();
  }, []);*/

  useEffect(() => {
    const fetchCupos = async () => {
      const data = await obtenerCuposActividades();
      if (data) {
        // Convierte el array en un objeto para fácil acceso
        const formattedCupos = data.data.reduce((acc, item) => {
          acc[item.actividad] = item.ocupados >= item.maximo;
          return acc;
        }, {});

        setCupos(formattedCupos);
        setCupos2(data.data);
        console.log('cupos', cupos2[1]);
      }
    };

    fetchCupos();
  }, []);

  const handleChange = (option) => {
    setOptions((prev) => {
      if (option === "option1") {
        return { ...prev, option1: !prev.option1, option2: false, option3: false };
      }
      if (option === "option4") {
        return { ...prev, option4: !prev.option4, option5: false, option7: false };
        //return { option4: !prev.option4, option5: false, option7: false };
      }
      if (option === "option5") {
        return { ...prev, option5: !prev.option5, option4: false };
      }
      if (option === "option6") {
        //return { ...prev, option6: !prev.option6, option7: false, option9: false };
        return { ...prev, option6: !prev.option6, option9: false, option10: false };
      }
      /*if (option === "option7" ||  option === "option9" ) {
        return { ...prev, option7: !prev.option7, option6: false };
      }*/
      if (option === "option8") {
        return { ...prev, option8: !prev.option8, option10: false };
      }
      if (option === "option10") {
        return { ...prev, [option]: !prev[option], option8: false };
      }
      if (option === "option11") {
        return { ...prev, option11: !prev.option11 };
      }
      if (option === "option12") {
        return { ...prev, option12: !prev.option12 };
      }
      if (option === "option13") {
        return { ...prev, option13: !prev.option13 };
      }
      return { ...prev, [option]: !prev[option] };
    });
  };


  const ref_form = React.createRef();
  const ref_organizacion = React.createRef();
  const ref_recaptcha = React.createRef();

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const dateFormat = 'YYYY/MM/DD';


  const elegirMedio = (value) => {
    console.log(value);

    if (value === "Otro") {
      setOtroMedio(true);
    } else {
      setOtroMedio(false);
    }
  };

  useEffect(() => {
    if (usuario) {
      app.app
        .auth()
        .signOut()
        .then(function () { })
        .catch(function (error) {
          // An error happened.
        });
    }
  }, []);

  useEffect(() => {
    setEmpresaRepetida(false);
  }, [tipo_perfl]);

  useEffect(() => {
    console.log("se reutiliza: ", reutilizar_datos);
    if (reutilizar_datos) {
      if (datos) {
        console.log("existen los datos");

        datos.nombre_curso = nom_evento;
        //datos.apellidos = datos.ap_paterno + " " + datos.ap_materno;

        mail_registro_confirmacion(datos).then(({ data, status }) => {
          switch (status) {
            case 0:
              //delete values.aceptar_terminos;
              app.escribir(
                nom_evento + "/usuarios",
                undefined,
                datos,
                () => { }
              );

              openNotificationWithIcon("success", "", data);
              setConfirmacion(true);
              break;
            default:
              openNotificationWithIcon("warning", "", data);
              setConfirmacion(false);
              break;
          }
          //setCargandoRegistro(false);
          setMostrarLogin(false);
        });
        setReutilizarDatos(false);
      } else {
        console.log("no existen los dtos");
      }
    }
  }, [datos, reutilizar_datos]);

  const registrarLoginExiste = (medio) => {
    setReutilizarDatos(true);
  };

  const onFinish = async (values) => {
    if (empresa_repetida) {
      openNotificationWithIcon(
        "warning",
        "YA EXISTE UN REGISTRO PARA ESTA EMPRESA",
        "Le recordamos que el evento presencial esta restringido a un registro por empresa, por lo que le sugerimos hacer el resto de sus inscipciones en el apartado REGISTRO A EVENTO VIRTUAL"
      );
      return;
    }

    setCargandoRegistro(true);

    let recaptcha = "sin-recaptcha"; //ref_recaptcha.current.getValue();

    values.correo = values.correo.toLowerCase();


    const { correo } = values;

    //setNombreCompleto(values.nombre + " " + values.ap_paterno);

    correo_registrado = correo;

    values.nombre_curso = nom_evento;
    values.personas = personasInscritas;

    //values.apellidos = values.ap_paterno;

    //values["g-recaptcha-response"] = recaptcha;
    perfil_registrado = values.perfil;

    const formattedValues = {
      ...values,
      actividad1: values.actividad1 ? values.actividad1.toString() : "false",
      actividad2: values.actividad2 ? values.actividad2.toString() : "false",
      actividad3: values.actividad3 ? values.actividad3.toString() : "false",
      actividad4: values.actividad4 ? values.actividad4.toString() : "false",
      actividad5: values.actividad5 ? values.actividad5.toString() : "false",
      actividad6: values.actividad6 ? values.actividad6.toString() : "false",
      actividad7: values.actividad7 ? values.actividad7.toString() : "false",
      actividad8: values.actividad8 ? values.actividad8.toString() : "false",
      actividad9: values.actividad9 ? values.actividad9.toString() : "false",
      actividad10: values.actividad10 ? values.actividad10.toString() : "false",
      actividad11: values.actividad11 ? values.actividad11.toString() : "false",
      actividad12: values.actividad12 ? values.actividad12.toString() : "false",
      actividad13: values.actividad13 ? values.actividad13.toString() : "false",
    };

    console.log("Valores enviados:", formattedValues);


    mail_registro_confirmacion(values).then(({ data, status }) => {
      switch (status) {
        case 0:
          console.log(values);
          //delete values.medios;
          //delete values.pass;
          delete values.aceptar_terminos;
          //delete values.aceptar_networking;
          //delete values.comite_infraestructura;
          //delete values.comite_asg;
          //delete values.comite_promocion;

          //delete values.otro_procedencia;

          openNotificationWithIcon("success", "", data);
          setConfirmacion(true);
          break;
        default:
          openNotificationWithIcon("warning", "", data);
          setConfirmacion(false);
          break;
      }
      setCargandoRegistro(false);
    });
  };

  const revisarEmpresa = (empresa) => {
    if (tipo_registro === "presencial") {
      existe_empresa({ empresa: empresa }).then(({ data }) => {
        if (data === 1) {
          openNotificationWithIcon(
            "warning",
            "YA EXISTE UN REGISTRO PARA ESTA EMPRESA",
            "Le recordamos que el evento presencial esta restringido a un registro por empresa, por lo que le sugerimos hacer el resto de sus inscipciones en el apartado REGISTRO A EVENTO VIRTUAL"
          );
          setEmpresaRepetida(true);
        } else {
          setEmpresaRepetida(false);
        }
      });
    }
  };

  const revisarPErfil = (perfil) => {
    if (perfil !== "") {
      setPerfilElegido(true);
    }
  };

  return (
    <>
      <Spin tip="Cargando..." spinning={cargando_regsitro}>
        <Row style={{ backgroundColor: "#ffffff" }}>

          <Col span={24}> <br /><br />
            <Row
              justify="center"
              align="middle"
              style={{ position: "relative" }}
            >
              <Col span={24} style={{ position: "relative" }}>
                <img
                  src={idioma === "es" ? banner : banner}
                  style={{ width: "100%" }}
                  className="curva"
                />
              </Col>
              <Col
                xs={20}
                md={15}
                style={{ position: "absolute", width: "100%" }}
              >
                <Row justify={'space-evenly'} align={'middle'}>
                  <Col xs={{span:8,offset:2}} lg={10}>
                    <img src={logo_banner_ergistro} style={{ width: "70%" }} />
                  </Col>
                  <Col xs={10} md={10}>
                    <p className="text_banner">
                      26 Y 27 DE FEBRERO
                    </p>
                    <p className="text_banner_3">
                      The St. Regis Mexico City
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          {confirmacion ? (
            <Col span={24}>
              <RegistroCompleto
                correo={correo_registrado}
                password={password_registrado}
                tipo_registro={tipo_registro}
                nombre={nombre_completo}
                idioma={idioma}
              />
            </Col>
          ) : (
            <>
              <Col xs={{ span: 20, offset: 2 }} md={{ span: 14, offset: 5 }}>
                <Form
                  className="login-form"
                  ref={ref_form}
                  form={form}
                  layout="vertical"
                  name="basic"
                  initialValues={{
                    remember: true,
                    procedencia: "",
                    checkin1: "2025-02-26",
                    checkout1: "2025-02-27",
                    checkin2: "2025-02-26",
                    checkout2: "2025-02-27",
                    checkin3: "2025-02-26",
                    checkout3: "2025-02-27",
                    checkin4: "2025-02-26",
                    checkout4: "2025-02-27",
                    checkin5: "2025-02-26",
                    checkout5: "2025-02-27",
                    checkin6: "2025-02-26",
                    checkout6: "2025-02-27"
                  }}
                  onFinish={onFinish}
                >
                  <Row>
                    <Col span={20} offset={2}>
                      <Row
                        justify="center"
                        align="middle"
                      >
                        <Col span={18} >
                          <p className="textos_titulos_secciones color_azul">REGISTRO</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                    >
                      <Form.Item style={{ display: 'non' }}
                        className="nom-label"
                        name="nombre1"
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                Ingresa tu nombre
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input
                          className="inputs"
                          autoComplete="nope"
                          placeholder="* Nombre(s):"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                    >
                      <Form.Item style={{ display: 'non' }}
                        className="nom-label"
                        name="ap_paterno1"
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                Ingresa tu apellido paterno
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input
                          className="inputs"
                          autoComplete="nope"
                          placeholder="* Apellido Paterno:"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                    >
                      <Form.Item style={{ display: 'non' }}
                        className="nom-label"
                        name="alias1"
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                Ingresa el nombre que quieres que aparezca en el Gafete
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input
                          className="inputs"
                          autoComplete="nope"
                          placeholder="* Nombre con el cual quiere aparecer en el gafete:"
                        />
                      </Form.Item>
                    </Col>
                    {/*<Col
                      xs={{ span: 24, offset: 0 }}
                      md={{ span: 11, offset: 2 }}
                    >
                      <Form.Item style={{ display: 'none' }}
                        className="nom-label"
                        name="genero1"
                        label={
                          <span className="labels ">
                            Genero:
                          </span>
                        }
                        rules={[
                          {
                            required: false,
                            message: (
                              <span style={{ color: "red" }} className="">
                                Ingresa tu genero
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input
                          className="inputs"
                          autoComplete="nope"
                        />
                      </Form.Item>
                      </Col>*/}
                    <Col xs={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}>
                      <Form.Item
                        className="nom-label"
                        name="correo"
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                Ingresa un correo válido
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input
                          className="inputs"
                          autoComplete="nope"
                          placeholder="* Correo electrónico: "
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}>
                      <Form.Item style={{ display: 'non' }}
                        className="nom-label"
                        name="perfil1"
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                {idioma === "es"
                                  ? "Selecciona un perfil"
                                  : "Select a profile"}
                              </span>
                            ),
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%", textAlign: "left", display: 'non' }}
                          onChange={(e) => {
                            setTipoPerfil(e);
                            console.log(
                              ref_form.current.resetFields(["procedencia"])
                            );
                          }}
                          placeholder={
                            <span className="labels ">
                              * Perfil (De clic en la opción deseada):
                            </span>
                          }
                        >
                          <Option value="Miembro AMPIP">Miembro AMPIP</Option>
                          <Option value="Patrocinador Platino">Patrocinador Platino</Option>
                          <Option value="Patrocinador Oro">Patrocinador Oro</Option>
                          <Option value="Patrocinador Oro">Patrocinador Bronce</Option>
                          <Option value="Invitado">
                            Invitado
                          </Option>
                          <Option value="Conferencista">
                            Conferencista
                          </Option>
                          <Option value="Equipo AMPIP">Equipo AMPIP</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    {tipo_perfl === "Miembro AMPIP" && (
                      <Col xs={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}>
                        <Form.Item
                          className="nom-label"
                          name="procedencia1"
                          rules={[
                            {
                              required: true,
                              message: (
                                <span style={{ color: "red" }} className="">
                                  Selecciona un asociado
                                </span>
                              ),
                            },
                          ]}
                        >
                          <Select
                            style={{ width: "100%", textAlign: "left" }}
                            onChange={(e) => {
                              setTipoMembresia(e);
                              console.log(
                                ref_form.current.resetFields(["procedencia"])
                              );
                            }}
                            placeholder={
                              <span className="labels ">
                                * Asociado{" "}
                                <small>
                                  (Da clic en la flecha para seleccionar la opción
                                  deseada)
                                </small>
                                :
                              </span>
                            }
                          >
                            <Option value="Advance">Advance</Option>
                            <Option value="AECOM">AECOM</Option>
                            <Option value="AeroTech">AeroTech</Option>
                            <Option value="Aire Proyectos">Aire Proyectos</Option>
                            <Option value="Alliuz">Alliuz</Option>
                            <Option value="American Industries">American Industries</Option>
                            <Option value="Amistad">Amistad</Option>
                            <Option value="AMMPER">AMMPER</Option>
                            <Option value="Ares">Ares</Option>
                            <Option value="Atisa">Atisa</Option>
                            <Option value="Aura">Aura</Option>
                            <Option value="Axial Grupo">Axial Grupo</Option>
                            <Option value="Axtel">Axtel</Option>
                            <Option value="Ayusa">Ayusa</Option>
                            <Option value="Baker Mckenzie">Baker Mckenzie</Option>
                            <Option value="Bancomext">Bancomext</Option>
                            <Option value="Banorte">Banorte</Option>
                            <Option value="BBVA">BBVA</Option>
                            <Option value="Bekaert">Bekaert</Option>
                            <Option value="Bermúdez">Bermúdez</Option>
                            <Option value="Bterra">Bterra</Option>
                            <Option value="BTS">BTS</Option>
                            <Option value="Cáctus">Cáctus</Option>
                            <Option value="Cadena">Cadena</Option>
                            <Option value="Calafia">Calafia</Option>
                            <Option value="Carlisle">Carlisle</Option>
                            <Option value="Carza Industrial Development">Carza Industrial Development</Option>
                            <Option value="CBP">CBP</Option>
                            <Option value="CBRE">CBRE</Option>
                            <Option value="Cementos Moctezuma">Cementos Moctezuma</Option>
                            <Option value="Cemex">Cemex</Option>
                            <Option value="Centinela Property">Centinela Property</Option>
                            <Option value="Centro Logistico Jalisco">Centro Logistico Jalisco</Option>
                            <Option value="Ciesa">Ciesa</Option>
                            <Option value="Colliers">Colliers</Option>
                            <Option value="Construcciones MS">Construcciones MS</Option>
                            <Option value="Construlita">Construlita</Option>
                            <Option value="Copachisa">Copachisa</Option>
                            <Option value="CPA">CPA</Option>
                            <Option value="CPKC">CPKC</Option>
                            <Option value="CREEL">CREEL</Option>
                            <Option value="Davisa">Davisa</Option>
                            <Option value="DeAcero">DeAcero</Option>
                            <Option value="Deltack">Deltack</Option>
                            <Option value="EgisMex">EgisMex</Option>
                            <Option value="El Florido">El Florido</Option>
                            <Option value="Empire">Empire</Option>
                            <Option value="Energía Real">Energía Real</Option>
                            <Option value="Enlight">Enlight</Option>
                            <Option value="EQT Exeter">EQT Exeter</Option>
                            <Option value="Estructuras Solana">Estructuras Solana</Option>
                            <Option value="Falcon">Falcon</Option>
                            <Option value="FIBRA Macquarie">FIBRA Macquarie</Option>
                            <Option value="FIBRA MTY">FIBRA MTY</Option>
                            <Option value="Fideicomiso Zacatecas">Fideicomiso Zacatecas</Option>
                            <Option value="Finsa ">Finsa </Option>
                            <Option value="Fortem Industrial">Fortem Industrial</Option>
                            <Option value="Frisa">Frisa</Option>
                            <Option value="Frontier">Frontier</Option>
                            <Option value="FUNO">FUNO</Option>
                            <Option value="Global Hitss">Global Hitss</Option>
                            <Option value="GP Desarrollos">GP Desarrollos</Option>
                            <Option value="Grupo Avante">Grupo Avante</Option>
                            <Option value="Grupo Bahn">Grupo Bahn</Option>
                            <Option value="Grupo Chufani">Grupo Chufani</Option>
                            <Option value="Grupo GMI">Grupo GMI</Option>
                            <Option value="Grupo Iamsa">Grupo Iamsa</Option>
                            <Option value="Grupo Nelson">Grupo Nelson</Option>
                            <Option value="GTM Inmobiliaria">GTM Inmobiliaria</Option>
                            <Option value="Hermosillo">Hermosillo</Option>
                            <Option value="Hikvision">Hikvision</Option>
                            <Option value="Hines">Hines</Option>
                            <Option value="Holcim">Holcim</Option>
                            <Option value="Huawei">Huawei</Option>
                            <Option value="Iberdrola">Iberdrola</Option>
                            <Option value="IFC">IFC</Option>
                            <Option value="Imobilem">Imobilem</Option>
                            <Option value="Industrialix">Industrialix</Option>
                            <Option value="Insur Construccion Industrial">Insur Construccion Industrial</Option>
                            <Option value="Interlogix">Interlogix</Option>
                            <Option value="Intermex">Intermex</Option>
                            <Option value="Interpuerto MTY">Interpuerto MTY</Option>
                            <Option value="Invex">Invex</Option>
                            <Option value="Isocindu">Isocindu</Option>
                            <Option value="JLL">JLL</Option>
                            <Option value="Jumbo City">Jumbo City</Option>
                            <Option value="Kampus">Kampus</Option>
                            <Option value="Kiewit">Kiewit</Option>
                            <Option value="Kingspan">Kingspan</Option>
                            <Option value="Lintel">Lintel</Option>
                            <Option value="Marabis">Marabis</Option>
                            <Option value="Maran">Maran</Option>
                            <Option value="Meor">Meor</Option>
                            <Option value="Millennnium">Millennnium</Option>
                            <Option value="MLC">MLC</Option>
                            <Option value="Mopec">Mopec</Option>
                            <Option value="MRP">MRP</Option>
                            <Option value="Naturgy">Naturgy</Option>
                            <Option value="Newmark">Newmark</Option>
                            <Option value="Nexus">Nexus</Option>
                            <Option value="Nicoya">Nicoya</Option>
                            <Option value="Novotech">Novotech</Option>
                            <Option value="O´Donnell">O´Donnell</Option>
                            <Option value="Oradel">Oradel</Option>
                            <Option value="Owens Corning">Owens Corning</Option>
                            <Option value="Parque Alis">Parque Alis</Option>
                            <Option value="Parque Industrial PYME">Parque Industrial PYME</Option>
                            <Option value="Parque Logístico San Julián">Parque Logístico San Julián</Option>
                            <Option value="Parque Opción">Parque Opción</Option>
                            <Option value="Pecari">Pecari</Option>
                            <Option value="PGIM">PGIM</Option>
                            <Option value="Piasa">Piasa</Option>
                            <Option value="Pimsa">Pimsa</Option>
                            <Option value="Plataforma Park">Plataforma Park</Option>
                            <Option value="Poligono Empresaria SM">Poligono Empresaria SM</Option>
                            <Option value="Powen">Powen</Option>
                            <Option value="Prologis">Prologis</Option>
                            <Option value="Prosperity">Prosperity</Option>
                            <Option value="Proximity Parks">Proximity Parks</Option>
                            <Option value="Puerta Querétaro">Puerta Querétaro</Option>
                            <Option value="RER">RER</Option>
                            <Option value="RMSG">RMSG</Option>
                            <Option value="Roca Desarrollos">Roca Desarrollos</Option>
                            <Option value="San Jorge">San Jorge</Option>
                            <Option value="Savills">Savills</Option>
                            <Option value="Skyplus">Skyplus</Option>
                            <Option value="Soprema">Soprema</Option>
                            <Option value="Stabilit">Stabilit</Option>
                            <Option value="Stiva">Stiva</Option>
                            <Option value="Telmex">Telmex</Option>
                            <Option value="Tensa">Tensa</Option>
                            <Option value="Terra Regia">Terra Regia</Option>
                            <Option value="Tetakawi">Tetakawi</Option>
                            <Option value="Thor Urbana">Thor Urbana</Option>
                            <Option value="Urbium">Urbium</Option>
                            <Option value="USG">USG</Option>
                            <Option value="Vesta">Vesta</Option>
                            <Option value="Vynmsa">Vynmsa</Option>
                            <Option value="World Port">World Port</Option>
                            <Option value="WTC SLP">WTC SLP</Option>
                            <Option value="Xsite">Xsite</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    )}

                    {(tipo_perfl === "Patrocinador Platino" || tipo_perfl === "Patrocinador Oro" || tipo_perfl === "Patrocinador  Bronce" || tipo_perfl === "Invitados" || tipo_perfl === "Conferencista") && (
                      <Col xs={{ span: 20, offset: 0 }}
                        md={{ span: 24, offset: 0 }}>
                        <Form.Item
                          className="nom-label"
                          name="procedencia1"
                          rules={[
                            {
                              required: true,
                              message: (
                                <span style={{ color: "red" }}>
                                  {idioma === "es"
                                    ? "Ingrese un Corporativo:"
                                    : "Enter a membership:"}
                                </span>
                              ),
                            },
                          ]}
                          initialValue=""
                        >
                          <input className="inputs" autoComplete="off"
                            placeholder="* Corporativo:" />
                        </Form.Item>
                      </Col>
                    )}
                    {(tipo_perfl === 'Equipo AMPIP' || tipo_perfl === '') &&
                      <Col xs={{ span: 0, offset: 0 }}
                        md={{ span: 12, offset: 0 }}>
                      </Col>}
                    <Col xs={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}>
                      <Form.Item style={{ display: 'non' }}
                        className="nom-label"
                        name="cargo1"
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }}>
                                {idioma === "es"
                                  ? "Ingresa tu cargo"
                                  : "Enter your position"}
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input className="inputs" autoComplete="off"
                          placeholder="* Cargo:" />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 0, offset: 0 }}
                      md={{ span: 0, offset: 0 }}>
                      <Form.Item style={{ display: 'none' }}
                        className="nom-label"
                        name="hotel1"
                        label={
                          <span className="labels">
                            Se hospeda en el Hotel Sede
                          </span>
                        }
                        rules={[
                          {
                            required: false,
                            message: (
                              <span style={{ color: "red" }} className="">
                                Selecciona una opción
                              </span>
                            ),
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%", textAlign: "left", display: 'non' }}
                          onChange={(e) => {
                            console.log(
                              ref_form.current.resetFields(["hotel"])
                            );
                          }}
                        >
                          <Option value="Si">Si</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{ textAlign: "left" }}>
                      <br />
                      <Form.Item
                        className=""
                        name="actividad13"
                        valuePropName="checked"
                      >
                        <Checkbox
                          className="visitas_texto"
                          onChange={() => handleChange("option13")}
                          checked={options.option13}
                          disabled={cupos.actividad13}
                        >
                          <strong>Visita a Parque Industrial O’Donnell el 27 de febrero <span className="color_morado">{cupos.actividad12 ? '(Cupo lleno)' : <small>

                            {cupos2.filter((acti) => {
                              if (acti.actividad === 'actividad13') {
                                return true
                              }
                              else { return false }
                            }).map((actividad) => (
                              <>
                                (espacios disponibles {actividad.maximo - actividad.ocupados})
                              </>
                            ))}
                          </small>}</span></strong>
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{ textAlign: "left" }}>
                      <br />
                      {!ver_actividades &&
                        <><strong>Para incribirte a las sesiones simultaneas del día 26 de febrero da clic <span className="color_morado" style={{ cursor: 'pointer' }} onClick={() => setVerActividades(true)}><strong>aquí</strong></span>.</strong></>
                      }
                    </Col>
                    {/* Actividad 1 */}
                    {ver_actividades &&
                      <>
                        <Col xs={12} md={12} className="tabla_titulo_actividad">
                          SALÓN JOHN JACOB III Y IV <small>(20 cupos por tema)</small>
                        </Col>
                        <Col xs={12} md={12} className="tabla_titulo_actividad">
                          SALÓN DIAMOND <small>(80 cupos por tema)</small>
                        </Col>
                        <Col xs={12} md={12} className="cuadro_opciones">
                          <br />
                          <Form.Item
                            className=""
                            name="actividad1"
                            valuePropName="checked"
                          >
                            <Checkbox
                              className=""
                              onChange={() => handleChange("option1")}
                              checked={options.option1}
                              disabled={cupos.actividad1 || options.option2 || options.option3}
                            >
                              09:15 - 11:00 Parque Industrial Seguro: retos y experiencias <span className="cupos_texto">{cupos.actividad1 ? '(Cupo lleno)' : <small>(espacios disponibles {cupos2[0].maximo - cupos2[0].ocupados})</small>}</span>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={12} className="cuadro_opciones">
                          <Row align={'top'}>
                            {/* Actividad 2 */}
                            <Col span={24} style={{ textAlign: "left" }}>
                              <br />
                              <Form.Item
                                className=""
                                name="actividad2"
                                valuePropName="checked"
                              >
                                <Checkbox
                                  className=""
                                  onChange={() => handleChange("option2")}
                                  checked={options.option2}
                                  disabled={cupos.actividad2 || options.option1}
                                >
                                  09:00 - 10:00	Gobernanza del Sistema Eléctrico Nacional: pilar de una transición energética justa <span className="cupos_texto">{cupos.actividad2 ? '(Cupo lleno)' : <small>(espacios disponibles {cupos2[1].maximo - cupos2[1].ocupados})</small>}</span>
                                </Checkbox>
                              </Form.Item>
                            </Col>

                            {/* Actividad 3 */}
                            <Col span={24} style={{ textAlign: "left" }}>
                              <br />
                              <Form.Item
                                className=""
                                name="actividad3"
                                valuePropName="checked"
                              >
                                <Checkbox
                                  className=""
                                  onChange={() => handleChange("option3")}
                                  checked={options.option3}
                                  disabled={cupos.actividad3 || options.option1}
                                >
                                  10:00 - 11:00	Microrredes y redes inteligentes en Parques Industriales: innovación para la eficiencia energética <span className="cupos_texto">{cupos.actividad3 ? '(Cupo lleno)' : <small>(espacios disponibles {cupos2[2].maximo - cupos2[2].ocupados})</small>}</span>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        {/* Actividad 4 */}
                        <Col xs={12} md={12} className="cuadro_opciones">
                          <br />
                          <Form.Item
                            className=""
                            name="actividad4"
                            valuePropName="checked"
                          >
                            <Checkbox
                              className=""
                              onChange={() => handleChange("option4")}
                              checked={options.option4}
                              disabled={cupos.actividad4 || options.option5 || options.option7}
                            >
                              11:30 - 13:00	Brainstorming con Japón: financiamiento, promoción de IED y capacitación <span className="cupos_texto">{cupos.actividad4 ? '(Cupo lleno)' : <small>(espacios disponibles {cupos2[3].maximo - cupos2[3].ocupados})</small>}</span>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={12} className="cuadro_opciones">
                          <Row align={'top'}>
                            {/* Actividad 5 */}
                            <Col xs={24} md={24} className="">
                              <br />
                              <Form.Item
                                className=""
                                name="actividad5"
                                valuePropName="checked"
                              >
                                <Checkbox
                                  className=""
                                  onChange={() => handleChange("option5")}
                                  checked={options.option5}
                                  disabled={cupos.actividad5 || options.option4}
                                >
                                  11:30 - 12:15	Código de red <span className="cupos_texto">{cupos.actividad5 ? '(Cupo lleno)' : <small>(espacios disponibles {cupos2[4].maximo - cupos2[4].ocupados})</small>}</span>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            {/* Actividad 7 */}
                            <Col xs={24} md={24} className="">
                              <br />
                              <Form.Item
                                className=""
                                name="actividad7"
                                valuePropName="checked"
                              >
                                <Checkbox
                                  className=""
                                  onChange={() => handleChange("option7")}
                                  checked={options.option7}
                                  disabled={cupos.actividad7 || options.option4}
                                >
                                  12:15 - 12:45	El futuro del mercado mexicano de sistemas de almacenamiento de energía <span className="cupos_texto">{cupos.actividad7 ? '(Cupo lleno)' : <small>(espacios disponibles {cupos2[6].maximo - cupos2[6].ocupados})</small>}</span>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        {/* Actividad 6 */}
                        <Col xs={12} md={12} className="cuadro_opciones">
                          <br />
                          <Form.Item
                            className=""
                            name="actividad6"
                            valuePropName="checked"
                          >
                            <Checkbox
                              className=""
                              onChange={() => handleChange("option6")}
                              checked={options.option6}
                              disabled={cupos.actividad6 || options.option9 || options.option10}
                            >
                              13:00 - 14:00	Estrategias de marketing digital para la promoción de parques industriales <span className="cupos_texto">{cupos.actividad6 ? '(Cupo lleno)' : <small>(espacios disponibles {cupos2[5].maximo - cupos2[5].ocupados})</small>}</span>
                            </Checkbox>
                          </Form.Item>
                        </Col>

                        <Col xs={12} md={12} className="cuadro_opciones">
                          <Row align={'top'}>

                            {/* Actividad 9 */}
                            <Col span={24} style={{ textAlign: "left" }}>
                              <br />
                              <Form.Item
                                className=""
                                name="actividad9"
                                valuePropName="checked"
                              >
                                <Checkbox
                                  className=""
                                  onChange={() => handleChange("option9")}
                                  checked={options.option9}
                                  disabled={cupos.actividad9 || options.option6}
                                >
                                  12:45 - 13:45	Gas natural como combustible limpio para Parques Industriales: retos y oportunidades <span className="cupos_texto">{cupos.actividad9 ? '(Cupo lleno)' : <small>(espacios disponibles {cupos2[8].maximo - cupos2[8].ocupados})</small>}</span>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            {/* Actividad 10 */}
                            <Col span={24} style={{ textAlign: "left" }}>
                              <br />
                              <Form.Item
                                className=""
                                name="actividad10"
                                valuePropName="checked"
                              >
                                <Checkbox
                                  className=""
                                  onChange={() => handleChange("option10")}
                                  checked={options.option10}
                                  disabled={cupos.actividad10 || options.option6}
                                >
                                  13:30 - 14:00	Conexión agrupada en Parques Industriales: estrategias de colaboración público-privada <span className="cupos_texto">{cupos.actividad10 ? '(Cupo lleno)' : <small>(espacios disponibles {cupos2[9].maximo - cupos2[9].ocupados})</small>}</span>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        {/* Actividad 8 */}
                        <Col xs={12} md={12} className="cuadro_opciones">
                          <br />
                          <Form.Item
                            className=""
                            name="actividad8"
                            valuePropName="checked"
                          >
                            <Checkbox
                              className=""
                              onChange={() => handleChange("option8")}
                              checked={options.option8}
                              disabled={cupos.actividad8 || options.option11}
                            >
                              15:30 - 16:00	Únete al Atlas AMPIP <span className="cupos_texto">{cupos.actividad8 ? '(Cupo lleno)' : <small>(espacios disponibles {cupos2[7].maximo - cupos2[7].ocupados})</small>}</span>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={{ span: 12 }}>
                          <Row align={'top'}>
                            {/* Actividad 11 */}
                            <Col span={24} className="cuadro_opciones">
                              <br />
                              <Form.Item
                                className=""
                                name="actividad11"
                                valuePropName="checked"
                              >
                                <Checkbox
                                  className=""
                                  onChange={() => handleChange("option11")}
                                  checked={options.option11}
                                  disabled={cupos.actividad11 || options.option8}
                                >
                                  15:30 - 16:30	Economía circular y parques industriales <span className="cupos_texto">{cupos.actividad11 ? '(Cupo lleno)' : <small>(espacios disponibles {cupos2[10].maximo - cupos2[10].ocupados})</small>}</span>
                                </Checkbox>
                              </Form.Item>
                            </Col>

                            <Col span={12} className=""></Col>
                            {/* Actividad 12 */}
                            <Col span={24} className="cuadro_opciones">
                              <br />
                              <Form.Item
                                className=""
                                name="actividad12"
                                valuePropName="checked"
                              >
                                <Checkbox
                                  className=""
                                  onChange={() => handleChange("option12")}
                                  checked={options.option12}
                                  disabled={cupos.actividad12}
                                >
                                  16:30 - 17:30	Gestión eficiente del agua en Parques Industriales: trámites y regulaciones <span className="cupos_texto">{cupos.actividad12 ? '(Cupo lleno)' : <small>(espacios disponibles {cupos2[11].maximo - cupos2[11].ocupados})</small>}</span>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>

                      </>
                    }


                    {/*<Col xs={{ span: 0, offset: 0 }}
                      md={{ span: 0, offset: 0 }}>
                      <Form.Item style={{ display: 'non' }}
                        className="nom-label"
                        name="checkin1"
                        label={
                          <span className="labels">
                            Fecha de llegada
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                Selecciona una fecha
                              </span>
                            ),
                          },
                        ]}
                      >*/}
                    {/*<DatePicker onChange={onChange} className="inputs" autoComplete="off" format={dateFormat}  />*/}
                    {/*<<input type="date"
                          className="inputs"
                          min="2024-06-18" max="2024-06-22"
                          placeholder="AAAA-MM-DD"></input>
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }}
                      md={{ span: 11, offset: 2 }}>
                      <Form.Item style={{ display: 'non' }}
                        className="nom-label"
                        name="checkout1"
                        label={
                          <span className="labels">
                            Fecha de salida
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                Selecciona una fecha
                              </span>
                            ),
                          },
                        ]}
                      >*/}
                    {/*<DatePicker onChange={onChange} className="inputs" autoComplete="off" format={dateFormat}  />*/}
                    {/*<<input type="date"
                          className="inputs"
                          min="2024-06-18" max="2023-11-30"
                          placeholder="AAAA-MM-DD"></input>
                      </Form.Item>
                    </Col>*/}

                    <Col span={24} style={{ textAlign: "left" }}>
                      <br />
                      <Form.Item
                        className="nom-label"
                        name="aceptar_terminos"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(
                                  <span style={{ color: "red" }}>
                                    {idioma === "es"
                                      ? "He leído y acepto los términos y condiciones."
                                      : "I have read and accept the terms and conditions."}
                                  </span>
                                ),
                          },
                        ]}
                      >
                        <Checkbox className="nom-label">
                          <span
                            className="color_texto_general"
                            style={{ fontSize: "16px" }}
                          >
                            {idioma === "es" ? (
                              <>
                                Acepto el{" "}
                                <span
                                  onClick={() => {
                                    window.open(
                                      "https://www.ampip.org.mx/aviso",
                                      "Aviso de privacidad",
                                      "top=100,left=500,width=600,height=600"
                                    );
                                  }}
                                >
                                  <strong>Aviso de Privacidad</strong>
                                </span>
                              </>
                            ) : (
                              <>
                                I accept{" "}
                                <span
                                  onClick={() => {
                                    window.open(
                                      "https://www.ampip.org.mx/aviso",
                                      "Aviso de privacidad",
                                      "top=100,left=500,width=600,height=600"
                                    );
                                  }}
                                >
                                  <strong>the Privacy Notice</strong>
                                </span>
                              </>
                            )}
                          </span>

                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item>
                        <br />
                        <p style={{ textArlign: "center" }}>
                          <span
                            className="btn_aqua font_bebas"
                            onClick={() => {
                              ref_form.current.submit();
                            }}
                          >
                            {idioma === "es" ? "REGISTRARME" : "REGISTER"}
                          </span>
                        </p>
                        <br />
                      </Form.Item>
                    </Col>
                  </Row>

                  <div id="recaptcha-container"></div>
                </Form>
              </Col>
              <Col xs={{ offset: 2, span: 20 }} md={{ offset: 6, span: 12 }}>
                <p className="texto_registro"><i>¿Problemas con tu registro?</i></p>
                <a
                  href="https://api.whatsapp.com/send?phone=525549826570&text=Tengo%20problemas%20con%20mi%20registro%20en%20AMPIP."
                  target="_blank"
                  className="btn_blanco font_bebas"
                >
                  CLIC AQUÍ
                </a>
              </Col>
              <Col span={24}>
                <br />
                <br />
              </Col>
            </>
          )}
        </Row>
      </Spin>

      <Modal
        title="INICIA SESIÓN"
        open={mostrar_login}
        onOk={() => {
          setMostrarLogin(false);
        }}
        onCancel={() => {
          setMostrarLogin(false);
        }}
        footer={null}
      >
        <LoginExiste
          onResponse={() => {
            registrarLoginExiste();
          }}
          onClick={success}
        />
      </Modal>
    </>
  );
};

export default withRouter(Registro);
