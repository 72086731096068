import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import '../../assets/css/costos.css';
import { FastBackwardOutlined, LeftOutlined } from '@ant-design/icons';


import costo_1 from '../../assets/images/primera_2025/COSTOS/costos_1reunion-.png';
import costo_2 from '../../assets/images/primera_2025/COSTOS/adic_costos_1reunion.png';
import costo_3 from '../../assets/images/primera_2025/COSTOS/cuentasMXN_1reunion.png';
import costo_4 from '../../assets/images/primera_2025/COSTOS/cuentasUSD_1reunion.png';


const Costos = (props) => {
    const { idioma } = props;
    return (
        <Row justify="center" className='back_costos_sec'>
            
            
            <Col xs={24}>
                <Row align={"bottom"} justify={"center"} className='costos_curva'>
                <Col xs={0} md={24}>
                <br /><br /><br /><br />
            </Col>
            <Col xs={24} md={0}>
                <br /><br />
            </Col>
                    <Col xs={24} md={20}>
                        <p className="textos_titulos_secciones">COSTOS</p>
                    </Col>
                    <Col xs={12} md={8}>
                        <img src={costo_1} style={{ width: '80%' }} />
                    </Col>
                    <Col xs={12} md={8}>
                        <img src={costo_2} style={{ width: '80%' }} />
                    </Col>
                    <Col span={18}>
                <span className='no_ver_chico'><br /></span><br />
                <p className="texto_costos color_texto_general">
                    <strong>Términos y condiciones</strong>
                    <lu className="color_morado">
                        <li><span className="color_texto_general">Incluye kit de bienvenida y acceso a todos los eventos incluidos en el programa.</span></li>
                        <li><span className="color_texto_general">No incluye transporte aéreo, ni hospedaje, ni transporte local.</span></li>
                        <li><span className="color_texto_general">La fecha límite para registro y beneficio de entradas sin costo es: viernes 14 de febrero de 2025.</span></li>
                        <li><span className="color_texto_general">La fecha límite para registro y pago de entradas adicionales es: viernes 14 de febrero 2025.</span></li>
                        <li><span className="color_texto_general">No aplican reembolsos en caso de cancelación.</span></li>
                        <li><span className="color_texto_general">Para la solicitud de entradas sin costo se requiere el registro previo.</span></li>
                        <li><span className="color_texto_general">Para la adquisición de entradas adicionales se requiere el registro previo y enviar un correo a <a href="mailto:ctoribio@ampip.org.mx" className='color_texto_general' target='_blank'>ctoribio@ampip.org.mx</a>, indicando el número de entradas adicionales y adjuntando la cédula de identificación fiscal para su facturación.</span></li>
                    </lu>
                </p><br />
                <span className='no_ver_chico'><br /><br /><br /><br /><br /><br /></span>
            </Col>
                </Row>
            </Col>
            

            <Col span={24} className=''>
                <br />
                <Row justify="center">
                    <Col xs={22}>
                        <p className='datos_sub color_blanco bold_font'><strong>DATOS BANCARIOS</strong></p>
                        <p className='transferen_text color_blanco'>Transferencia electrónica, pago en una sola exhibición</p>
                        <br />
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col xs={20} md={10} className=''>
                        <img src={costo_3} style={{ width: '100%' }} />
                    </Col>
                    <Col xs={0} md={0}>
                        <br /><br />
                    </Col>
                    <Col xs={20} md={10} className=''>
                        <img src={costo_4} style={{ width: '100%' }} />
                    </Col>
                </Row>
                <br />
            </Col>
        </Row>
    )
}
export default Costos;