import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import { Link } from "react-router-dom";
import "../../assets/css/acercade.css";

import cuadro from "../../assets/images/primera_2025/ACERCADE/imagen_acercade_1reunion.png";
import lugar from "../../assets/images/primera_2025/ACERCADE/ic_ubicacion_1reunion_-15.png";
import fecha from "../../assets/images/primera_2025/ACERCADE/ic_fechas_1reunion_-16.png";
import limite from "../../assets/images/primera_2025/ACERCADE/ic_estrella_1reunion_-17.png";
import Contador from "../evento/Contador";

const Acercade = (props) => {


  return (
    <>
    <Row justify={'center'} style={{padding:'3% 0%'}}>
        <Col xs={{span:20}} md={{span:12}}>
            <Row style={{position:'relative'}}>
                <Col xs={{span:24}} style={{position:'relative'}}>
                <img src={cuadro} className="img_contador" />
                </Col>
                <Col xs={{span:6, offset:16}} md={{span:4, offset:16}} style={{position:'absolute',width:'100%'}} className="col_Contador">
                <Contador
                    dateTarget="02/26/2025 10:00:00 AM"
                  />
                </Col>
            </Row>
       
            
        </Col>
        <Col xs={{span:20}} md={{span:8}}>
        <p className="textos_titulos_secciones color_azul" style={{textAlign:'left'}}>NUESTRO EVENTO</p>
            <p className="acercade_texto">"Participa en el evento que conecta a los líderes más influyentes del sector industrial en México. Un espacio único para el networking estratégico, el intercambio de ideas y la construcción de alianzas que impulsan el futuro de los parques industriales”.</p>
            <br /><br />
            <Row justify={'center'}>
                <Col xs={{span:4}}>
                    <img src={lugar} style={{width:'30px'}} />
                </Col>
                <Col xs={{span:18}}>
                    <p className="acercade_info">Hotel St. Regis, Ciudad de México</p><br />
                </Col>
                <Col xs={{span:4}}>
                    <img src={fecha} style={{width:'30px'}} />
                </Col>
                <Col xs={{span:18}}>
                    <p className="acercade_info"><strong>26 y 27 de febrero,</strong> 2025 8:00 h</p><br />
                </Col>
                <Col xs={{span:4}}>
                    <img src={limite} style={{width:'30px'}} />
                </Col>
                <Col xs={{span:18}}>
                    <p className="acercade_info">Último día de registro <strong>14 febrero 2025</strong></p><br />
                </Col>
            </Row>
        </Col>
    </Row>
    </>
  );
};
export default Acercade;
