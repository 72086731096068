import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import ICalendarLink from "react-icalendar-link";
import { Element } from "react-scroll";

import Agenda from "./Agenda";
import Ponentes from "./Ponentes";

import "../../assets/css/webinars.css";

import banner from "../../assets/images/primera_2025/banner_principal.jpg";

import pdf_patrocinadores from '../../assets/documentos/AMPIPeventos 2025_patrocinios.pdf';
import pdf_agenda from '../../assets/documentos/AMPIPagenda1areunion2025ingles_.pdf';

import btn_ayuda from "../../assets/images/segunda_2024/PNG/btn_whats_morado.png";
import btn_ayuda_en from "../../assets/images/segunda_2024/PNG/btn_whats_morado.png";

import logo_banner_ergistro from "../../assets/images/primera_2025/logo_1reunion_2025.png";
import btn_registro from "../../assets/images/segunda_2024/PNG/btn_registro_gris.png";


import "../../assets/css/conferencia.css";
import Contador from "../evento/Contador";
import Membresia from "../membresia/Membresia";
import Hospedaje from "../membresia/Hospedaje";
import CarouselPatrocinadores from "../evento/CarouselPatrocinadores";
import Acercade from "./Acercade";

const Webinars = (props) => {
  const { idioma } = props;


  return (
    <>
      <Row>
        <Col span={24}>
          <Row style={{ position: "relative" }}>
            <Col span={24} style={{ position: "relative" }}>
              <img src={banner} style={{ width: "100%" }} className="curva" />
            </Col>
            <Col
              span={24}
              offset={0}
              className="contenedor_banner_menu"
              style={{ position: "absolute", width: "100%" }}
            >
              <Row justify="center">
                <Col xs={0} md={24}>
                  <br />
                </Col>
                <Col xs={0} md={0}>
                  <Contador
                    dateTarget="09/07/2022 10:00:00 AM"
                    idioma={idioma}
                  />
                </Col>
                <Col xs={24} md={0}>
                  <br />
                </Col>
                <Col xs={22} md={18}>
                  <Row justify={'space-around'} align={'middle'}>
                    <Col xs={{offset:6,span:6}}  md={{offset:0,span:10}}>
                      <img src={logo_banner_ergistro} style={{ width: "100%" }} />
                    </Col>
                    <Col xs={12} md={10}>
                      <p className="text_banner">
                        26 Y 27 DE FEBRERO
                      </p>
                      <p className="text_banner_3">
                        The St. Regis Mexico City
                      </p>
                      <br />
                      <p style={{ textAlign: 'left' }}>
                        <Link to="/registro" className="btn_gris">
                          REGISTRO</Link>
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="">
        <Col id="agenda" span={24} className="">
          <Element name="#agenda"></Element>
          <Agenda idioma={idioma} />
        </Col>
        <Col span={24} className="paddin_contenedor">
          <Row justify="center" align="middle">
            <Col xs={24} md={0}>
              <br />
            </Col>
            <Col xs={{ span: 20, offset: 0 }} md={{ span: 20, offset: 0 }}>
              <Link className="btn_patrocinio_hoome" to={pdf_agenda} target="_blank">
                <strong className="">DOWNLOAD THE AGENDA IN ENGLISH</strong>
              </Link>
            </Col>
            <Col xs={24} md={0}>
              <br />
            </Col>
          </Row>
        </Col>
        <Col id="acercade" span={24}>
          <Element name="#acercade"></Element>
          <Acercade idioma={idioma} />
        </Col>
        <Col id="conferencistas" span={0}>
          <Element name="#conferencistas"></Element>
          <Ponentes idioma={idioma} />
        </Col>
        <Col id="hospedaje" span={24}>
          <Element name="#hospedaje"></Element>
          <Hospedaje idioma={idioma} />
        </Col>
        <Col span={0} className="ayuda_back paddin_contenedor">
          <Row justify="center" align="middle">
            <Col xs={{ span: 20, offset: 0 }} md={{ span: 12, offset: 0 }}>
              <p className="bold_font dudas_btn color_azul">
                {idioma === "es"
                  ? "Tengo dudas sobre mi inscripción"
                  : "I have questions about my registration"}
              </p>
            </Col>
            <Col xs={{ span: 0, offset: 0 }} md={{ span: 6, offset: 0 }}>
              <a
                href={
                  idioma === "es"
                    ? "https://api.whatsapp.com/send?phone=525549826570&text=Tengo%20problemas%20con%20mi%20registro%20en%20AMPIP."
                    : "https://api.whatsapp.com/send?phone=525549826570&text=I%20have%20problems%20with%20my%20AMPIP%20registration."
                }
                target="_blank"
              >
                <img
                  src={idioma === "es" ? btn_ayuda : btn_ayuda_en}
                  style={{ width: "230px" }}
                />
              </a>
            </Col>
            <Col xs={{ span: 20, offset: 0 }} md={{ span: 0, offset: 0 }}>
              <a
                href={
                  idioma === "es"
                    ? "https://api.whatsapp.com/send?phone=525549826570&text=Tengo%20problemas%20con%20mi%20registro%20en%20AMPIP."
                    : "https://api.whatsapp.com/send?phone=525549826570&text=I%20have%20problems%20with%20my%20AMPIP%20registration."
                }
                target="_blank"
              >
                <img
                  src={idioma === "es" ? btn_ayuda : btn_ayuda_en}
                  style={{ width: "150px" }}
                />
              </a>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Element name="#membresia"></Element>
          <Membresia idioma={idioma} />
        </Col>
        <Col span={0}>

          <Element name="#patrocinadores"></Element>
          <CarouselPatrocinadores />
        </Col>
        <Col span={24} className="back_ayuda">
        <Element name="#patrocinadores"></Element>
          <Row justify="center" align={'bottom'}>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 0 }}>
              
              <p className="costo_patrocinio_hoome color_azul_oscuro" style={{textAlign:'center'}}>
                ¡Construyamos juntos el futuro industrial de México!
              </p>
              <br />
              <Link className="btn_patrocinio_hoome font_bebas" to={pdf_patrocinadores} target="_blank">
                <strong className="">Información sobre patrocinio</strong>
              </Link>
            </Col>
          </Row>
        </Col>




      </Row>
    </>
  );
};

export default Webinars;
