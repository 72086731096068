import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import '../../assets/css/hospedaje.css';

import agotado from '../../assets/images/segunda_2024/cupoagotado_ampip2024.jpg';

import costo_delux from '../../assets/images/primera_2025/HOSPEDAJE/DELUXEROOM_B_1reunion.png';
import costo_delux_select from '../../assets/images/primera_2025/HOSPEDAJE/DELUXEROOM_A_1reunion.png';
import costo_gd from '../../assets/images/primera_2025/HOSPEDAJE/GRANDDELUXE_B_1reunion.png';
import costo_gd_select from '../../assets/images/primera_2025/HOSPEDAJE/GRANDDELUXE_A_1reunion.png';
import costo_exe from '../../assets/images/primera_2025/HOSPEDAJE/EXECUTIVE_B_1reunion.png';
import costo_exe_select from '../../assets/images/primera_2025/HOSPEDAJE/EXECUTIVE_A_1reunion.png';
import costo_luxu from '../../assets/images/primera_2025/HOSPEDAJE/LUXURY_B_1reunion.png';
import costo_lux_select from '../../assets/images/primera_2025/HOSPEDAJE/LUXURY_A_1reunion.png';

import icon_1 from '../../assets/images/primera_2025/HOSPEDAJE/mail_1reunion.png';
import icon_2 from '../../assets/images/primera_2025/HOSPEDAJE/tel_1reunion.png';
import icon_3 from '../../assets/images/primera_2025/HOSPEDAJE/masinfo_1reunion.png';


const Hospedaje = (props) => {
    const [ver_select, setVerSelect] = useState(0);
    const { idioma } = props;


    return (
        <>
        <Row className='back_hospedaje_'>
            <Col xs={{span:24, offset:0}} md={{span:20, offset:2}} className=''>
                <Row justify='center' align='middle' style={{ position: '' }}>
                    
                    <Col span={24} offset={0} style={{ position: '', width: '100%' }}><br />
                        <p className="textos_titulos_secciones color_azul">HOSPEDAJE</p>
                    </Col>
                </Row>
            </Col>

            <Col xs={{span:22, offset:1}} md={{span:16, offset:4}}>
                <Row justify='center'>
                    <Col xs={22} md={20}>
                        <p className='color_azul text_safi font_bebas'>Hotel St. Regis, Ciudad de México</p>
                        <p className='color_texto_general text_safi_ubicacion'>
                        Av. Paseo de la Reforma 439, Cuauhtémoc, 06500, <br />
                        Ciudad de México. Tel. <a className='color_texto_general' href='tel:+525552281818'>55 5228 1818</a>
                        </p>
                       <br /><br />
                        <img src={agotado} style={{width:'100%',display:'none'}} />
                    </Col>
                </Row>
            </Col>
            <Col xs={{span:22, offset:1}} md={{span:24, offset:0}}>
                <Row justify={'center'}>
                    <Col xs={12} md={5} onMouseEnter={()=>setVerSelect(1)} onMouseLeave={()=>setVerSelect(0)}>
                        <img src={ver_select === 1 ? costo_delux : costo_delux_select} style={{width:'100%'}} />    
                    </Col>
                    <Col xs={12} md={5} onMouseEnter={()=>setVerSelect(2)} onMouseLeave={()=>setVerSelect(0)}>
                        <img src={ver_select === 2 ? costo_gd : costo_gd_select} style={{width:'100%'}} />    
                    </Col>
                    <Col xs={12} md={5} onMouseEnter={()=>setVerSelect(3)} onMouseLeave={()=>setVerSelect(0)}>
                        <img src={ver_select === 3 ? costo_exe : costo_exe_select} style={{width:'100%'}} />    
                    </Col>
                    <Col xs={12} md={5} onMouseEnter={()=>setVerSelect(4)} onMouseLeave={()=>setVerSelect(0)}>
                        <img src={ver_select === 4 ? costo_luxu : costo_lux_select} style={{width:'100%'}} />    
                    </Col>
                </Row>
            </Col>
            
            <Col xs={{span:20, offset:2}} md={{span:20, offset:2}} style={{display:'non'}}>
                <p className="texto_hospedaje color_texto_general ">
                <strong>Términos y condiciones</strong>
                <ul className='color_texto_general' style={{fontWeight:'bold'}}>
                    <li><span className="color_texto_general">Tarifas en base ocupación sencilla y en plan europeo sujetas al 16% de impuesto y al 3.5% de impuesto de hospedaje.</span></li>
                    <li><span className="color_texto_general">El tipo de cambio será aplicable en la llegada del huésped.</span></li>
                    <li><span className="color_texto_general">Propinas no incluidas: Housekeeping: $6.00 usd por noche, Butler $5.50 usd por noche y Bellman: $5.50 usd por persona.</span></li>
                    <li><span className="color_texto_general">Cancelación sin cargo, hasta el 30 de enero del 2025, por lo tanto, no se reembolsará a partir de esta fecha, en ninguna circunstancia.</span></li>
                    <li><span className="color_texto_general">Cualquier cambio en la reservación podría incurrir en cambios de tarifa.</span></li>
                </ul>
                </p>
                
                        
                    </Col>
        </Row>
        <Row className='back_azul_oscuro' style={{padding:'2% 0%'}}>
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }}>
                    <Row justify={'center'} align={'middle'}>
                        <Col xs={20} md={24}>
                            <p className="texto_hospedaje_3 color_blanco">
                                <strong>MÉTODO DE RESERVACIONES</strong>
                            </p>
                            <p className="texto_hospedaje_4 color_blanco">
                                Las reservaciones son realizadas y garantizadas por cada participante
                            </p>


                        </Col>
                        <Col xs={24} style={{padding:'2%'}}>
                            <Row justify={'space-around'}>
                                <Col xs={{span:20}} md={{span:6}}>
                                    <img src={icon_1} className='icon_link_h'/>
                                    <p className='links_hospedaje'>
                                        <a className='color_blanco  hover_aqua' href='mailto:reservations.mexicocity2@stregishotels.com'>reservations.mexicocity2@stregishotels.com</a>
                                    </p>
                                </Col>
                                <Col xs={{span:12}} md={{span:6}}>
                                    <img src={icon_2} className='icon_link_h'/>
                                    <p className='links_hospedaje'>
                                        <a className='color_blanco  hover_aqua' href='tel:+525552281883'>5255 5228 1883</a>
                                    </p>
                                </Col>
                                <Col xs={{span:12}} md={{span:6}}>
                                    <img src={icon_3} className='icon_link_h'/>
                                    <p className='links_hospedaje'>
                                        <a className='color_blanco  hover_aqua' href='https://www.marriott.com/event-reservations/reservation-link.mi?id=1735257960172&key=GRP&guestreslink2=true&app=resvlink' target='_blank'>Reserva aquí</a>
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={22} md={22}>
                            <p className='texto_hospedaje_2 color_blanco'>
                            <i>
                            AMPIP no realiza reservaciones de habitaciones. Favor de hacerlas directamente al hotel.
                            </i>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}
export default Hospedaje;